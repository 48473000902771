import React, { useState } from 'react';

export default () => {
  const [submitted, setSubmitted] = useState(false);
  const [state, setState] = React.useState({});

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');
  }

  const handleChange = (e) => {
    console.log(state);
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => setSubmitted(true))
      .catch((error) => alert(error));
  };

  return (
    <section id="contact">
      <div className="container pl-0 pr-0">
        <div className="row">
          <div className="col-lg-12 mx-auto text-center">
            {/*<i className="far fa-paper-plane fa-2x mb-2" />*/}
            <h2 className="mb-5">
              Not Ready to Book an Appointment Just yet?
              <br />
              Reach Out and We will answer any question for you!
            </h2>
            {submitted ? (
              <div className="inline-block align-middle w-full text-2xl text-center text-white text-uppercase">
                Your message was sent. Someone from our team will be with you
                shortly! Please feel free to reach out by phone for immediate
                assistance at 727-444-0655.
              </div>
            ) : (
              <form
                className="form-inline d-flex"
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <div className="d-flex flex-sm-row flex-column p-2 col-lg-12">
                  <input
                    type="text"
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    id="first-name"
                    placeholder="Enter First Name..."
                    name="first-name"
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    id="last-name"
                    placeholder="Enter Last Name..."
                    name="last-name"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="d-flex flex-sm-row flex-column p-2 col-lg-12">
                  <input
                    type="tel"
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    id="phone"
                    placeholder="Enter Phone Number..."
                    name="phone"
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="email"
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    id="email"
                    placeholder="Enter Email Address..."
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="d-flex p-2 col-lg-12">
                  <textarea
                    rows="4"
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    id="message"
                    placeholder="ENTER MESSAGE..."
                    name="message"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="d-flex p-2 col-lg-12">
                  <button type="submit" className="btn btn-primary mx-auto">
                    Connect Now!
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
