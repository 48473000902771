import React from 'react';
import { Link } from 'gatsby';

export default function Footer() {
  return (
    <footer className="cco-blue small text-center text-white">
      <div className="container pb-5">
        Copyright &copy; CCO Concierge LLC - 2022 - <Link className="text-white" to={'../privacy'}>Privacy Policy</Link>
      </div>
    </footer>
  );
}
